export const state = () => ({
  roles: {},
  role: {},
  pagination: {},
})

export const mutations = {
  SET_STATE_ROLES(state, { type, value }) {
    state[type] = value
  },
}

export const actions = {
  async fetchRoles({ commit }, data) {
    let params = {}
    if (!data.search) {
      params = {
        page: data.page,
        per_page: data.pageSize,
      }
    } else {
      params = {
        search: data.search,
        page: data.page,
        per_page: data.pageSize,
      }
    }
    const response = await this.$repository.v4.everest.roles.getRoles(params)
    if (response.success) {
      commit('SET_STATE_ROLES', {
        type: 'roles',
        value: { ...response.result },
      })

      commit('SET_STATE_ROLES', {
        type: 'pagination',
        value: {
          pageSize: response.result.paginate.per_page,
          current: response.result.paginate.current_page,
          total: response.result.paginate.total,
        },
      })
    }
    return response
  },
  async fetchRoleById({ commit }, roleId) {
    const response = await this.$repository.v4.everest.roles.getRole(roleId)
    if (response.success) {
      commit('SET_STATE_ROLES', {
        type: 'role',
        value: response.result,
      })
    }
    return response
  },
  async storeRole({ commit }, data) {
    return await this.$repository.v4.everest.roles.storeRole(data)
  },
  async updateRole({ commit }, data) {
    return await this.$repository.v4.everest.roles.updateRole(data.id, data)
  },
  async import({ commit }, data) {
    return await this.$repository.v4.everest.roles.import(data)
  },
}
