export const state = () => ({
  skills: {},
  skill: {},
  pagination: {},
})

export const mutations = {
  SET_STATE_SKILLS(state, { type, value }) {
    state[type] = value
  },
}

export const actions = {
  async fetchSkills({ commit }, data) {
    const params = {
      page: data.page,
      per_page: data.pageSize,
      ...(data.search && { search: data.search }),
    }

    const response = await this.$repository.v4.everest.skills.getSkills(params)
    if (response.success) {
      commit('SET_STATE_SKILLS', {
        type: 'skills',
        value: { ...response.result },
      })

      commit('SET_STATE_SKILLS', {
        type: 'pagination',
        value: {
          pageSize: response.result.paginate.per_page,
          current: response.result.paginate.current_page,
          total: response.result.paginate.total,
        },
      })
    }
    return response
  },

  async fetchSkillsByDeveloperType({ commit }, data) {
    const response =
      await this.$repository.v4.everest.skills.getSkillsByDeveloperType(
        data.developerType,
        {
          page: data.page,
          per_page: data.pageSize,
        }
      )
    if (response.success) {
      commit('SET_STATE_SKILLS', {
        type: 'skills',
        value: { ...response.result },
      })
    }
    return response
  },
  async storeSkill({ commit }, data) {
    return await this.$repository.v4.everest.skills.storeSkill(data)
  },
  async showSkill({ commit }, skillId) {
    const response = await this.$repository.v4.everest.skills.showSkill(skillId)
    if (response.success) {
      commit('SET_STATE_SKILLS', {
        type: 'skill',
        value: response.result,
      })
    }
    return response
  },
  async updateSkill({ commit }, data) {
    return await this.$repository.v4.everest.skills.updateSkill(data.id, data)
  },
}
